Drupal.behaviors.slider_slick_main = {
  attach: function (context) {
    let $slick = $('#main-slider .slider-inner', context);
    let prev = '<button class="slider-control slider-control-prev"><svg xmlns="http://www.w3.org/2000/svg" width="21.5" height="44.5"><title>Назад</title><path fill-rule="evenodd" stroke="currentColor" stroke-width="5" fill="none" d="M3.47 37.6l6.833-16.601-6.833-16.6" transform="scale(-1,1) translate(-21.5,0)"/></svg></button>';
    let next = '<button class="slider-control slider-control-next"><svg xmlns="http://www.w3.org/2000/svg" width="21.5" height="44.5"><title>Вперёд</title><path fill-rule="evenodd" stroke="currentColor" stroke-width="5" fill="none" d="M3.47 37.6l6.833-16.601-6.833-16.6"/></svg></button>';

    $slick.slick({
      prevArrow: prev,
      nextArrow: next,
      dots: true,
      fade: true,
      touchMove: false,
      swipe: false,
      autoplay: true,
      autoplaySpeed: 3500,
    });

    let $slick_inner_clouds = $('.slide-clouds .slide-items', $slick);

    $slick_inner_clouds.slick({
      arrows: false,
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 544,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1240,
          settings: 'unslick'
        },
      ],
    });

    let $slick_inner_tariffs = $('.slide-tariffs .slide-items', $slick);

    $slick_inner_tariffs.slick({
      arrows: false,
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '40px',
          }
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '40px',
          }
        },
        {
          breakpoint: 1240,
          settings: 'unslick'
        },
      ],
    });

    $(window).on('resize orientationchange', function () {
      $slick_inner_clouds.slick('resize');
      $slick_inner_tariffs.slick('resize');
    });
  }
};
