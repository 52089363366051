Drupal.behaviors.mobilenav = {
  attach: function (context) {
    if (jQuery().slicknav) {
      var $menu = $('.region-navigation .navbar-nav').first();
      $menu.once('mobilenav', function () {
        var $container = $('<div id="sai-mobilenav"></div>').prependTo('body');
        $menu.slicknav({
          label: 'Навигация',
          closeOnClick: true,
          removeClasses: true,
          prependTo: $container,
          afterOpen: function () {
            $('body').css('min-height', $('#sai-mobilenav .slicknav_menu').height() + 'px');
          }
        });
      });
    }
  }
};
